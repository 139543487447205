<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create inventory audit</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start m-2 custom-input">
            <div class="row">
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray"> Deadline </label>
                <DatePicker
                  v-model="audit.deadline_at"
                  :onlyPicker="false"
                  :show-picker="false"
                  :onlyInRange="0"
                  :show-time="false"
                  :only-one="true"
                />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray"> Users </label>
                <v-select
                  :options="users"
                  v-model="audit.users"
                  label="last_name"
                  multiple
                  placeholder="Search users"
                  :reduce="(c) => c.id"
                  append-to-body
                >
                  <template v-slot:selected-option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                  <template v-slot:option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                </v-select>
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <h5 class="mb-2">Type</h5>
                <label class="cstm-check dropdown-item">
                  <span class="text-black mx-2 regular-14">
                    Continuous inventory audit
                  </span>
                  <input type="radio" v-model="audit.type" :value="1" /><span
                    class="checkmark mx-2"
                  ></span>
                </label>
                <label class="cstm-check dropdown-item">
                  <span class="text-black mx-2 regular-14">
                    Full inventory audit
                  </span>
                  <input type="radio" v-model="audit.type" :value="2" /><span
                    class="checkmark mx-2"
                  ></span>
                </label>
                <label class="cstm-check dropdown-item">
                  <span class="text-black mx-2 regular-14">
                    Categories inventory audit
                  </span>
                  <input type="radio" v-model="audit.type" :value="3" /><span
                    class="checkmark mx-2"
                  ></span>
                </label>
              </div>
              <div class="col-12 mb-3 whitebg-input" v-if="audit.type == 1">
                <label class="form-label bold-12 text-gray">
                  Item quantity
                </label>
                <input class="form-control" v-model="audit.quantity" />
              </div>
              <div class="col-12 mb-3 whitebg-input" v-if="audit.type == 3">
                <label class="form-label bold-12 text-gray">
                  Item categories
                </label>
                <v-select
                  :options="categories"
                  v-model="audit.categories"
                  label="name"
                  multiple
                  placeholder="Search categories"
                  :reduce="(c) => c.id"
                  append-to-body
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addAaudit"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import DatePicker from "../inputs/DatePicker.vue";
import { useStore } from "vuex";
const bootstrap = require("bootstrap");

export default {
  name: "NewInventoryAudit",
  props: {
    modelValue: Object,
    statuses: Array,
  },
  emits: ["update:modelValue", "add"],
  data() {
    return {
      store: useStore(),
      myModal: undefined,
      success: false,
      loadAddBtn: false,
      audit: {
        type: 1,
        quantity: 20,
      },
      users: [],
      categories: [],
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.loadData();
      this.myModal.show();
    },
    setUpCategories(data, str = "") {
      if (data.categories !== undefined) {
        for (const category of data.categories) {
          this.setUpCategories(
            category,
            data.name !== undefined ? `${str}${data.name} - ` : ""
          );
        }
      } else {
        this.categories.push({
          name: `${str}${data.name}`,
          top: data.name,
          sub: str,
          id: data.id,
        });
      }
    },
    loadData() {
      http
        .fetch("/users/")
        .then((data) => {
          this.users = data;
        })
        .catch(() => {
          this.users = [];
        });
      http.fetch(`/products/products/categories?language=en`).then((data) => {
        this.categories = [];
        this.setUpCategories({ categories: data.data });
      });
    },
    addAaudit() {
      this.loadAddBtn = true;
      this.audit.warehouse = this.warehouse;
      http
        .fetch("/warehouses/outgoing/inventory-audit", this.audit, true)
        .then((data) => {
          this.loadAddBtn = false;
          this.myModal.hide();
          this.$emit("add", data.row);
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
  },
  components: { DatePicker },
};
</script>
